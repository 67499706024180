import moment from 'moment'

function formatDateSLA(date) {
	if (!date) return ' '
	return moment(date).format('DD / MM / YYYY')
}

export function average(nums) {
	if (!nums) return 0
	return nums.reduce((a, b) => a + b) / nums.length
}

export function formatterSlaOpen(cel, row) {
	if (!row.open || !row.investigation) return ''
	//Transformar fechas
	const given = moment(row.open, 'YYYY-MM-DD')
	const current = moment(row.investigation, 'YYYY-MM-DD')
	let difference = current.diff(given, 'day')
	// the business days (Monday to Friday)
	let businessDays = difference
	while (difference > 0) {
		const date = given.add(1, 'days')
		const weekDay = date.isoWeekday()
		if (weekDay === 6 || weekDay === 7) businessDays -= 1
		difference -= 1
	}
	return businessDays <= 3 ? 'On Time' : 'Out Of Time'
}

export function formatterDaysOpen(cel, row) {
	if (!row.open || !row.investigation) return ' '
	//Transformar fechas
	const given = moment(row.open, 'YYYY-MM-DD')
	const current = moment(row.investigation, 'YYYY-MM-DD')
	let difference = current.diff(given, 'day')
	// the business days (Monday to Friday)
	let businessDays = difference
	while (difference > 0) {
		const date = given.add(1, 'days')
		const weekDay = date.isoWeekday()
		if (weekDay === 6 || weekDay === 7) businessDays -= 1
		difference -= 1
	}
	return businessDays < 0 ? -1 : businessDays
}

export function daysOpen(row) {
	if (!row.open || !row.investigation) return null
	//Transformar fechas
	const given = moment(row.open, 'YYYY-MM-DD')
	const current = moment(row.investigation, 'YYYY-MM-DD')
	let difference = current.diff(given, 'day')
	// the business days (Monday to Friday)
	let businessDays = difference
	while (difference > 0) {
		const date = given.add(1, 'days')
		const weekDay = date.isoWeekday()
		if (weekDay === 6 || weekDay === 7) businessDays -= 1
		difference -= 1
	}

	return businessDays < 0 ? -1 : businessDays
}

export function formatterSlaInvestigation(cel, row) {
	if (!row.investigation || !row.ready) return ' '
	//Transformar fechas
	const given = moment(row.investigation, 'YYYY-MM-DD')
	const current = moment(row.ready, 'YYYY-MM-DD')
	let difference = current.diff(given, 'day')
	// the business days (Monday to Friday)
	let businessDays = difference
	while (difference > 0) {
		const date = given.add(1, 'days')
		const weekDay = date.isoWeekday()
		if (weekDay === 6 || weekDay === 7) businessDays -= 1
		difference -= 1
	}

	return businessDays <= 20 ? 'On Time' : 'Out Of Time'
}

export function formatterDaysInv(cel, row) {
	if (!row.investigation || !row.ready) return ' '
	//Transformar fechas
	const given = moment(row.investigation, 'YYYY-MM-DD')
	const current = moment(row.ready, 'YYYY-MM-DD')
	let difference = current.diff(given, 'day')
	// the business days (Monday to Friday)
	let businessDays = difference
	while (difference > 0) {
		const date = given.add(1, 'days')
		const weekDay = date.isoWeekday()
		if (weekDay === 6 || weekDay === 7) businessDays -= 1
		difference -= 1
	}

	return businessDays < 0 ? -1 : businessDays
}

export function daysInvestigation(row) {
	if (!row.investigation || !row.ready) return null
	//Transformar fechas
	const given = moment(row.investigation, 'YYYY-MM-DD')
	const current = moment(row.ready, 'YYYY-MM-DD')
	let difference = current.diff(given, 'day')
	// the business days (Monday to Friday)
	let businessDays = difference
	while (difference > 0) {
		const date = given.add(1, 'days')
		const weekDay = date.isoWeekday()
		if (weekDay === 6 || weekDay === 7) businessDays -= 1
		difference -= 1
	}

	return businessDays < 0 ? -1 : businessDays
}

export function formatterSlaReady(cel, row) {
	if (!row.ready || !row.close) return ' '
	//Transformar fechas
	const given = moment(row.ready, 'YYYY-MM-DD')
	const current = moment(row.close, 'YYYY-MM-DD')
	let difference = current.diff(given, 'day')
	// the business days (Monday to Friday)
	let businessDays = difference
	while (difference > 0) {
		const date = given.add(1, 'days')
		const weekDay = date.isoWeekday()
		if (weekDay === 6 || weekDay === 7) businessDays -= 1
		difference -= 1
	}

	return businessDays <= 5 ? 'On Time' : 'Out Of Time'
}

export function formatterDaysReady(cel, row) {
	if (!row.ready || !row.close) return ' '
	//Transformar fechas
	const given = moment(row.ready, 'YYYY-MM-DD')
	const current = moment(row.close, 'YYYY-MM-DD')
	let difference = current.diff(given, 'day')
	// the business days (Monday to Friday)
	let businessDays = difference
	while (difference > 0) {
		const date = given.add(1, 'days')
		const weekDay = date.isoWeekday()
		if (weekDay === 6 || weekDay === 7) businessDays -= 1
		difference -= 1
	}

	return businessDays < 0 ? -1 : businessDays
}

export function daysReady(row) {
	if (!row.ready || !row.close) return null
	//Transformar fechas
	const given = moment(row.ready, 'YYYY-MM-DD')
	const current = moment(row.close, 'YYYY-MM-DD')
	let difference = current.diff(given, 'day')
	// the business days (Monday to Friday)
	let businessDays = difference
	while (difference > 0) {
		const date = given.add(1, 'days')
		const weekDay = date.isoWeekday()
		if (weekDay === 6 || weekDay === 7) businessDays -= 1
		difference -= 1
	}

	return businessDays < 0 ? -1 : businessDays
}

export const columnsSla = [
	{
		dataField: 'reportId',
		text: 'Denuncia',
		sort: true
	},
	{
		dataField: 'open',
		text: 'Abierta',
		sort: true,
		formatter: formatDateSLA
	},
	{
		dataField: 'Dias Abierta',
		text: 'Días',
		sort: true,
		formatter: formatterDaysOpen
	},
	{
		dataField: 'investigation',
		text: 'Investigación',
		sort: true,
		formatter: formatDateSLA
	},
	{
		dataField: 'Dias Investigación',
		text: 'Días',
		sort: true,
		formatter: formatterDaysInv
	},
	{
		dataField: 'ready',
		text: 'Lista para dictamen',
		sort: true,
		formatter: formatDateSLA
	},
	{
		dataField: 'Dias Lista para dictamen',
		text: 'Días',
		sort: true,
		formatter: formatterDaysReady
	},
	{
		dataField: 'close',
		text: 'Cerrada',
		sort: true,
		formatter: formatDateSLA
	}
]

export const columnsTime = [
	{
		dataField: 'reportId',
		text: 'Denuncia',
		sort: true
	},
	{
		dataField: 'Abierta',
		text: 'SLA Open',
		sort: true,
		formatter: formatterSlaOpen
	},
	{
		dataField: 'Investigacion',
		text: 'SLA Investigation',
		sort: true,
		formatter: formatterSlaInvestigation
	},
	{
		dataField: 'Lista para dictamen',
		text: 'SLA Ready for Opinion',
		sort: true,
		formatter: formatterSlaReady
	}
]
