import React, { useEffect, useState } from 'react'

import { Row, Col } from 'reactstrap'
import { Bar } from 'react-chartjs-2'

import { Panel } from './../Dashboard/Panel'
import DashboardLayout from './../Dashboard/DashboardLayout'
import { columnsIntegrity, columnsDiversity } from './columns'

import axios from 'axios'
import moment from 'moment'
import { chartData, lineData } from './../../helpers'

export const Visits = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	  }, []);
	const lineOptions = {
		scales: { yAxes: [ { ticks: { min: 0, stepSize: 10 } } ] },
		legend: { display: false },
		plugins: {
			datalabels: { display: false }
		}
	}

	const [ visits, setVisits ] = useState({})
	async function requestVisits() {
		const result = await axios.post('/api/visits/visitsIntegrity', {
			startDate,
			endDate
		})
		const labels = result.data.map((l) => `${l.type}`)
		const data = result.data.map((v) => v.value)
		const chart = chartData('Visits', labels, data)
		setVisits(chart)
	}

	const [ toExport, setToExport ] = useState([])
	async function reportIntegrity() {
		const result = await axios.post('/api/visits/reportIntegrity')
		setToExport(result.data)
	}

	const [ visitsDiversity, setVisitsDiversity ] = useState({})

	async function requestVisitsDiversity() {
		const result = await axios.post('/api/visits/visitsDiversity', {
			startDate,
			endDate
		})
		const labels = result.data.map((l) => `${l.type}`)
		const data = result.data.map((v) => v.value)
		const chart = chartData('Visits', labels, data)
		setVisitsDiversity(chart)
	}

	const [ toExportDiversity, setToExportDiversity ] = useState([])
	async function reportDiversity() {
		const result = await axios.post('/api/visits/reportDiversity')
		setToExportDiversity(result.data)
	}

	const [ activityVisitsIntegrity, setActivityVisitsIntegrity ] = useState({})
	async function requestActivityVisitsIntegrity() {
		const result = await axios.post('/api/visits/activityVisitsIntegrity', {
			startDate,
			endDate
		})

		const labels = [
			'Enero',
			'Febrero',
			'Marzo',
			'Abril',
			'Mayo',
			'Junio',
			'Julio',
			'Agosto',
			'Septiembre',
			'Octubre',
			'Noviembre',
			'Diciembre'
		]

		const data = result.data.reduce((pre, cur) => {
			const month = moment(cur.type).month()
			pre[month] += cur.value
			return pre
		}, [ ...new Array(12) ].fill(0))

		const chart = lineData('Activity', labels, data)
		setActivityVisitsIntegrity(chart)
	}

	const [ activityVisitsDiversity, setActivityVisitsDiversity ] = useState({})
	async function requestActivityVisitsDiversity() {
		const result = await axios.post('/api/visits/activityVisitsDiversity', {
			startDate,
			endDate
		})

		const labels = [
			'Enero',
			'Febrero',
			'Marzo',
			'Abril',
			'Mayo',
			'Junio',
			'Julio',
			'Agosto',
			'Septiembre',
			'Octubre',
			'Noviembre',
			'Diciembre'
		]

		const data = result.data.reduce((pre, cur) => {
			const month = moment(cur.type).month()
			pre[month] += cur.value
			return pre
		}, [ ...new Array(12) ].fill(0))

		const chart = lineData('Activity', labels, data)
		setActivityVisitsDiversity(chart)
	}

	useEffect(() => {
		async function init() {
			await requestVisits()
			await requestVisitsDiversity()
			await requestActivityVisitsIntegrity()
			await requestActivityVisitsDiversity()
			await reportIntegrity()
			await reportDiversity()
		}
		init()
	}, [])

	const [ startDate, setStartDate ] = useState('')
	const [ endDate, setEndDate ] = useState('')
	const [ error, setError ] = useState('')

	async function handleOnSubmit(event) {
		event.preventDefault()

		if (Date.parse(startDate) > Date.parse(endDate)) {
			//start is less than End
			setError('La fecha de inicio debe ser menor a la de fin.')
			return
		}

		setError('')
		await requestVisits()
		await requestVisitsDiversity()
		await requestActivityVisitsIntegrity()
		await requestActivityVisitsDiversity()
		await reportIntegrity()
		await reportDiversity()
	}

	const [ clear, setClear ] = useState(false)
	function handleClickClear() {
		setStartDate('')
		setEndDate('')
		setClear(true)
	}

	async function handleClickRefresh() {
		await requestVisits()
		await requestVisitsDiversity()
		await requestActivityVisitsIntegrity()
		await requestActivityVisitsDiversity()
		await reportIntegrity()
		await reportDiversity()
		setClear(false)
	}

	return (
		<DashboardLayout>
			<Row>
				<Col className="d-flex flex-row align-items-center">
					<form onSubmit={handleOnSubmit}>
						<ul className="list-inline">
							<li className="list-inline-item">
								<div className="input-group">
									<input
										type="date"
										name="startDate"
										className="form-control border-0"
										value={startDate}
										onChange={(event) => setStartDate(event.target.value)}
									/>
									<input
										type="date"
										name="endDate"
										className="form-control  border-0"
										value={endDate}
										onChange={(event) => setEndDate(event.target.value)}
									/>
									<div style={{ marginLeft: '10px' }}>
										<button className="btn btn-primary" type="submit">
											<i className="fas fa-search fa-sm" />
										</button>
									</div>
									{(startDate || endDate) && (
										<div style={{ marginLeft: '10px' }}>
											<button className="btn btn-primary" onClick={handleClickClear}>
												Clean Fields
											</button>
										</div>
									)}
									{clear && (
										<div style={{ marginLeft: '10px' }}>
											<button className="btn btn-primary" onClick={handleClickRefresh}>
												Start
											</button>
										</div>
									)}
									{error && (
										<p style={{ margin: '5px' }} className="text-danger">
											{error}
										</p>
									)}
								</div>
							</li>
						</ul>
					</form>
				</Col>
			</Row>

			<br />
			{''}
			<Row>
				<Col xl="12" lg="12">
					<Panel title="Visits Integrity" enableExport columnsToExport={columnsIntegrity} data={toExport}>
						<Bar data={visits} width={100} height={20} options={lineOptions} />
					</Panel>
				</Col>
			</Row>
			<Row>
				<Col xl="12" lg="12">
					<Panel title="Activity Chart Integrity">
						<Bar data={activityVisitsIntegrity} width={100} height={20} options={lineOptions} />
					</Panel>
				</Col>
			</Row>
			<Row>
				<Col xl="12" lg="12">
					<Panel
						title="Visits Diversity"
						enableExport
						columnsToExport={columnsDiversity}
						data={toExportDiversity}
					>
						<Bar data={visitsDiversity} width={100} height={20} options={lineOptions} />
					</Panel>
				</Col>
			</Row>
			<Row>
				<Col xl="12" lg="12">
					<Panel title="Activity Chart Diversity">
						<Bar data={activityVisitsDiversity} width={100} height={20} options={lineOptions} />
					</Panel>
				</Col>
			</Row>
		</DashboardLayout>
	)
}

export default Visits
