import React from 'react'
import { connect } from 'react-redux'

export const LoginForm = props => {
  const { email, password, remember } = props.values

  function handleOnChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const key = target.name
    props.onChange({ ...props.values, [key]: value })
  }

  return (
    <form onSubmit={props.onSubmit} className='user'>
      <div className='form-group'>
        <input
          type='email'
          className='form-control form-control-user'
          id='exampleInputEmail'
          aria-describedby='emailHelp'
          placeholder='Correo electrónico'
          name='email'
          value={email}
          onChange={handleOnChange}
        />
      </div>
      <div className='form-group'>
        <input
          type='password'
          className='form-control form-control-user'
          id='exampleInputPassword'
          placeholder='Contraseña'
          name='password'
          value={password}
          onChange={handleOnChange}
        />
      </div>
      <div className='form-group'>
        <div className='custom-control custom-checkbox small'>
          <input
            type='checkbox'
            className='custom-control-input'
            id='customCheck'
            name='remember'
            value={remember}
            onChange={handleOnChange}
          />
          <label className='custom-control-label' htmlFor='customCheck'>
            Recordar cuenta
          </label>
        </div>
      </div>
      <button className='btn btn-primary btn-user btn-block'>Iniciar sesión</button>
    </form>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
