import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { call, put, all, takeLatest } from 'redux-saga/effects'
import { isExpire, setAuthToken } from './auth.helpers'
import { actions, types } from './auth.redux'

/**
 * Login the user to the system.
 * @param   {object}   action.payload Data passed when the action was dispatched
 * @returns {iterator}
 */
export function* loginSaga({ payload }) {
	try {
		const url = '/api/auth/login'
		const { data } = yield call(axios.post, url, payload)
		const { token } = data
		if (token) {
			// Set token on localStorage
			localStorage.setItem('auth_token', token)
			// Set token in axios Header
			setAuthToken(token)
			// Decode token to get user data
			const decoded = jwt_decode(token)
			const { data: profile } = yield call(axios.get, '/api/users/user')
			yield put(actions.loginSuccess({ ...decoded, ...profile }))
		}
	} catch (error) {
		yield put(actions.loginFailure(error))
		console.log(error)
	}
}

/**
 * Logout the user on the system
 * 1 - Remove the token from localstorage
 * 2 - Remove the auth headers
 * 3 - Clean up the store->user redux node
 * @returns {iterator}
 */
export function* logoutSaga() {
	try {
		localStorage.removeItem('auth_token')
		setAuthToken(false)
		yield put(actions.logoutSuccess())
	} catch (error) {
		yield put(actions.logoutFailure())
		console.log(error)
	}
}

export function* checkLogin() {
	try {
		const token = localStorage.getItem('auth_token')
		if (token) {
			setAuthToken(token) // Set token to Auth header
			const decoded = jwt_decode(token) // Decode token to get user data
			if (decoded && isExpire(decoded.exp)) return yield put(actions.logoutRequest())
			const { data: profile } = yield call(axios.get, '/api/users/user')
			if (profile.active !== 1) yield put(actions.logoutRequest())
			return yield put(actions.loginSuccess({ ...decoded, ...profile }))
		}
	} catch (error) {
		yield put(actions.loginFailure(error))
	}
}

export function* authSagas() {
	yield all([
		takeLatest(types.LOGIN_REQUEST, loginSaga),
		takeLatest(types.LOGOUT_REQUEST, logoutSaga),
		takeLatest(types.CHECK_LOGIN_REQUEST, checkLogin)
	])
}
