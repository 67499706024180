import moment from 'moment'

export function formatDate(date) {
	return moment(date).format('DD / MM / YYYY, h:mm:ss a')
}

export function formatDateLine(date) {
	return moment(date).format('D / MM / YYYY')
}

export const columns = [
	{
		dataField: 'reportId',
		text: 'Denuncia',
		sort: true
	},
	{
		dataField: 'category',
		text: 'Categoría',
		sort: true
	},
	{
		dataField: 'area',
		text: 'Área',
		sort: true
	},
	{
		dataField: 'status',
		text: 'Estatus',
		sort: true
	},
	{
		dataField: 'comment',
		text: 'Descripción',
		sort: true
	},
	{
		dataField: 'email',
		text: 'Email',
		sort: true
	},
	{
		dataField: 'created',
		text: 'Creado',
		sort: true,
		formatter: formatDate
	}
]
