import { createActions, createReducer } from 'reduxsauce'
import { isEmpty } from '../../helpers'

const INITIAL_STATE = {
  name: '',
  lastName: '',
  email: '',
  isAuthenticated: false
}

const { Creators, Types } = createActions(
  {
    loginRequest: ['payload'],
    loginSuccess: ['payload'],
    loginFailure: ['payload'],

    checkLoginRequest: ['payload'],
    checkLoginSuccess: ['payload'],
    checkLoginFailure: ['payload'],

    logoutRequest: ['payload'],
    logoutSuccess: ['payload'],
    logoutFailure: ['payload']
  },
  {}
)

export const HANDLERS = {
  [Types.LOGIN_SUCCESS]: (_state, action) => {
    const user = action.payload || {}
    const isAuthenticated = !isEmpty(user)
    return { ...user, isAuthenticated }
  },
  [Types.LOGOUT_SUCCESS]: () => INITIAL_STATE
}

export const actions = Creators
export const types = Types
export const auth = createReducer(INITIAL_STATE, HANDLERS)
