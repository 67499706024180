import { mdy } from './../../helpers/date.helpers'

export const columnsIntegrity = [
	{ title: 'Page', value: (data) => data.type },
	{ title: 'Date', value: (data) => mdy(data.date) },
	{ title: 'IP', value: (data) => data.ip }
]

export const columnsDiversity = [
	{ title: 'Page', value: (data) => data.type },
	{ title: 'Date', value: (data) => mdy(data.date) },
	{ title: 'IP', value: (data) => data.ip }
]
