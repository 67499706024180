import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Row, Col } from 'reactstrap'
import { Bar, Pie, Doughnut } from 'react-chartjs-2'

import { Panel, Card, Heading } from './Panel'
import DashboardLayout from './DashboardLayout'
import DataTable from './../Table/Table'
import { columns } from './columns'

import axios from 'axios'
import moment from 'moment'
import { chartData, lineData } from './../../helpers'

export const Dashboard = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	  }, []);
	const user = useSelector((state) => state.auth)
	const role = user.role

	const barOptionsPercentage = {
		scales: { yAxes: [ { ticks: { min: 0, stepSize: 10 } } ] },
		legend: { display: false },
		plugins: {
			datalabels: {
				formatter: (value, ctx) => {
					let sum = 0
					let dataArr = ctx.chart.data.datasets[0].data
					dataArr.map((data) => {
						sum += data
					})
					let percentage = (value * 100 / sum).toFixed() + '%'
					return percentage
				},
				color: '#fff'
			}
		},
		tooltips: {
			callbacks: {
				label: function(tooltipItems, data) {
					return data.labels[tooltipItems.index]
				}
			}
		}
	}

	const donutOptions = {
		legend: { display: true, position: 'bottom' },
		plugins: {
			datalabels: {
				formatter: (value, ctx) => {
					let sum = 0
					let dataArr = ctx.chart.data.datasets[0].data
					dataArr.map((data) => {
						sum += data
					})
					let percentage = (value * 100 / sum).toFixed() + '%'
					return percentage
				},
				color: '#fff'
			}
		},
		tooltips: {
			callbacks: {
				label: function(tooltipItems, data) {
					return data.labels[tooltipItems.index]
				}
			}
		}
	}

	const circleOptions = {
		legend: { display: true, position: 'right' },
		plugins: {
			datalabels: { display: false }
		},
		tooltips: {
			callbacks: {
				label: function(tooltipItems, data) {
					return data.labels[tooltipItems.index]
				}
			}
		}
	}

	const lineOptions = {
		scales: { yAxes: [ { ticks: { min: 0, stepSize: 10 } } ] },
		legend: { display: false },
		plugins: {
			datalabels: { display: false }
		}
	}

	const [ offices, setOffices ] = useState([])
	const [ filteredOffices, setFilteredOffices ] = useState([])
	const [ filteredCommittes, setFilteredCommittes ] = useState([])

	async function requestOffices() {
		const result = await axios.post('/api/inputs/offices')
		const data = result.data
		const officeList = user.role >= 5 ? data : data.filter((item) => item.committee_id === user.committee_id)
		setOffices(data)
		setFilteredOffices(officeList)
	}

	const [ committes, setCommittes ] = useState([])
	async function requestCommittes() {
		const result = await axios.post('/api/inputs/committes')
		const data = result.data
		setCommittes(data)
		setFilteredCommittes(data)
	}

	const [ countries, setCountries ] = useState([])
	async function requestCountries() {
		const result = await axios.post('/api/inputs/countries')
		const data = result.data
		setCountries(data)
	}

	const [ status, setStatus ] = useState({})
	const [ open, setOpen ] = useState(0)
	const [ close, setClose ] = useState(0)
	const [ total, setTotal ] = useState(0)
	async function requestStatus() {
		// get request
		const result = await axios.post('/api/reports/status', { startDate, endDate, office, country, committe })
		// set char
		const labels = result.data.map((l) => `${l.type}`)
		const data = result.data.map((v) => v.value)
		const chart = chartData('Status', labels, data)
		setStatus(chart)
		// set open
		const open = result.data.reduce(
			(a, b) => (b.type !== 'Concluded' && b.type !== 'Discharted' ? b.value + a : a),
			0
		)
		const close = result.data.reduce(
			(a, b) => (b.type === 'Concluded' || b.type === 'Discharted' ? b.value + a : a),
			0
		)

		const total = open + close
		setOpen(open)
		setClose(close)
		setTotal(total)
	}

	const [ category, setCategory ] = useState({})
	async function requestCategory() {
		const result = await axios.post('/api/reports/category', {
			startDate,
			endDate,
			office,
			country,
			committe
		})
		const labels = result.data.map((l) => `${l.type}`)
		const data = result.data.map((v) => v.value)
		const chart = chartData('Category', labels, data)
		setCategory(chart)
	}

	const [ subcategory, setSubCategory ] = useState({})
	async function requestSubCategory() {
		const result = await axios.post('/api/reports/subcategory', {
			startDate,
			endDate,
			office,
			country,
			committe
		})
		const labels = result.data.map((l) => `${l.type}`)
		const data = result.data.map((v) => v.value)
		const chart = chartData('Sub Category', labels, data)
		setSubCategory(chart)
	}

	const [ area, setArea ] = useState({})
	async function requestArea() {
		const result = await axios.post('/api/reports/area', { startDate, endDate, office, country, committe })
		const labels = result.data.map((l) => `${l.type}`)
		const data = result.data.map((v) => v.value)
		const chart = chartData('Category', labels, data)
		setArea(chart)
	}

	const [ since, setSince ] = useState({})
	async function requestSince() {
		const result = await axios.post('/api/reports/since', { startDate, endDate, office, country, committe })
		const labels = result.data.map((l) => `${l.type}`)
		const data = result.data.map((v) => v.value)
		const chart = chartData('Since', labels, data)
		setSince(chart)
	}

	const [ conclution, setConclution ] = useState({})
	async function requestConclution() {
		const result = await axios.post('/api/reports/conclution', {
			startDate,
			endDate,
			office,
			country,
			committe
		})
		const labels = result.data.map((l) => `${l.type}`)
		const data = result.data.map((v) => v.value)
		const chart = chartData('Conclution', labels, data)
		setConclution(chart)
	}

	const [ plan, setPlan ] = useState({})
	async function requestPlan() {
		const result = await axios.post('/api/reports/plan', {
			startDate,
			endDate,
			office,
			country,
			committe
		})
		const labels = result.data.map((l) => `${l.type}`)
		const data = result.data.map((v) => v.value)
		const chart = chartData('Plan de Remediación', labels, data)
		setPlan(chart)
	}

	const [ subPlan, setSubPlan ] = useState({})
	async function requestSubPlan() {
		const result = await axios.post('/api/reports/subplan', {
			startDate,
			endDate,
			office,
			country,
			committe
		})
		const labels = result.data.map((l) => `${l.type}`)
		const data = result.data.map((v) => v.value)
		const chart = chartData('Plan de Remediación', labels, data)
		setSubPlan(chart)
	}

	const [ offence, setOffence ] = useState({})
	async function requestOffence() {
		const result = await axios.post('/api/reports/offence', {
			startDate,
			endDate,
			office,
			country,
			committe
		})
		const labels = result.data.map((l) => `${l.type}`)
		const data = result.data.map((v) => v.value)
		const chart = chartData('Offence', labels, data)
		setOffence(chart)
	}

	const [ activity, setActivity ] = useState({})
	async function requestActivity() {
		const result = await axios.post('/api/reports/activity', {
			startDate,
			endDate,
			office,
			country,
			committe
		})

		const labels = [
			'Enero',
			'Febrero',
			'Marzo',
			'Abril',
			'Mayo',
			'Junio',
			'Julio',
			'Agosto',
			'Septiembre',
			'Octubre',
			'Noviembre',
			'Diciembre'
		]

		const data = result.data.reduce((pre, cur) => {
			const month = moment(cur.type).month()
			pre[month] += cur.value
			return pre
		}, [ ...new Array(12) ].fill(0))

		const chart = lineData('Activity', labels, data)
		setActivity(chart)
	}

	const [ activityDetail, setActivityDetail ] = useState([])
	async function requestActivityDetail() {
		const result = await axios.post('/api/reports/activity/detail', {
			startDate,
			endDate,
			office,
			country,
			committe
		})
		const data = result.data
		setActivityDetail(data)
	}

	useEffect(() => {
		async function init() {
			await requestCategory()
			await requestSubCategory()
			await requestStatus()
			await requestSince()
			await requestActivity()
			await requestArea()
			await requestActivityDetail()
			await requestOffence()
			await requestConclution()
			await requestPlan()
			await requestSubPlan()
			await requestOffices()
			await requestCommittes()
			await requestCountries()
		}
		init()
	}, [])

	const [ startDate, setStartDate ] = useState('')
	const [ endDate, setEndDate ] = useState('')
	const [ country, setCountry ] = useState('')
	const [ office, setOffice ] = useState('')
	const [ committe, setCommitte ] = useState('')
	const [ error, setError ] = useState('')

	async function handleOnSubmit(event) {
		event.preventDefault()

		if (Date.parse(startDate) > Date.parse(endDate)) {
			//start is less than End
			setError('La fecha de inicio debe ser menor a la de fin.')
			return
		}

		setError('')

		await requestCategory()
		await requestSubCategory()
		await requestStatus()
		await requestSince()
		await requestActivity()
		await requestArea()
		await requestActivityDetail()
		await requestOffence()
		await requestConclution()
		await requestPlan()
		await requestSubPlan()
	}

	const [ clear, setClear ] = useState(false)
	function handleClickClear() {
		setStartDate('')
		setEndDate('')
		setCountry('')
		setOffice('')
		setCommitte('')
		setClear(true)
	}

	async function handleClickRefresh() {
		await requestOffices()
		await requestCommittes()
		await requestCountries()
		await requestCategory()
		await requestSubCategory()
		await requestStatus()
		await requestSince()
		await requestActivity()
		await requestArea()
		await requestActivityDetail()
		await requestOffence()
		await requestConclution()
		await requestPlan()
		await requestSubPlan()
		setClear(false)
	}

	function handleChangeCountry(event) {
		const value = event.target.value
		const officesByCountry = offices.filter((item) => item.country === value)
		const committesId = [ ...new Set(officesByCountry.map((item) => item.committee_id)) ]
		const committesByCountry = committes.filter((item) => committesId.includes(item.id))
		setCountry(value)
		setFilteredOffices(officesByCountry)
		setFilteredCommittes(committesByCountry)
	}

	function handleChangeCommitte(event) {
		const value = event.target.value
		const officesByCommitte = offices.filter((item) => item.committee_id === parseInt(value))
		setCommitte(value)
		setFilteredOffices(officesByCommitte)
	}

	function handleChangeOffice(event) {
		const value = event.target.value
		setOffice(value)
	}

	return (
		<DashboardLayout>
			{role >= 5 ? (
				<Row>
					<Col lg="4">
						<form onSubmit={handleOnSubmit}>
							<div className="input-group">
								<select
									name="country"
									className="form-control border-0"
									value={country}
									onChange={handleChangeCountry}
								>
									<option value="" selected="selected">
										Selecciona el país
									</option>
									{countries.map((item) => (
										<option key={`country_${item.id}`} value={item.countryName}>
											{item.countryName}
										</option>
									))}
								</select>
							</div>
						</form>
					</Col>
					<Col lg="4">
						<form onSubmit={handleOnSubmit}>
							<div className="input-group">
								<select
									name="committe"
									className="form-control  border-0 "
									value={committe}
									onChange={handleChangeCommitte}
								>
									<option value="" selected="selected">
										Selecciona el comité
									</option>
									{filteredCommittes.map((item) => (
										<option key={`committe_${item.id}`} value={item.id}>
											{item.commiteName}
										</option>
									))}
								</select>
							</div>
						</form>
					</Col>
					<Col lg="4">
						<form onSubmit={handleOnSubmit}>
							<div className="input-group">
								<select
									name="office"
									className="form-control  border-0 "
									value={office}
									onChange={handleChangeOffice}
								>
									<option value="" selected="selected">
										Selecciona la oficina
									</option>
									{filteredOffices.map((item) => (
										<option key={`office_${item.id}`} value={item.id}>
											{item.officeName}
										</option>
									))}
								</select>
							</div>
						</form>
					</Col>
				</Row>
			) : (
				<Row>
					<Col lg="6">
						<Heading committee={user.committee_title} />
					</Col>
					<Col lg="4">
						<form onSubmit={handleOnSubmit}>
							<div className="input-group">
								<select
									name="office"
									className="form-control  border-0 "
									value={office}
									onChange={handleChangeOffice}
								>
									<option value="" selected="selected">
										Selecciona la oficina
									</option>
									{filteredOffices.map((item) => (
										<option key={`officeRole_${item.id}`} value={item.id}>
											{item.officeName}
										</option>
									))}
								</select>
							</div>
						</form>
					</Col>
				</Row>
			)}
			<br />
			{''}
			<Row>
				<Col className="d-flex flex-row align-items-center">
					<form onSubmit={handleOnSubmit}>
						<ul className="list-inline">
							<li className="list-inline-item">
								<div className="input-group">
									<input
										type="date"
										name="startDate"
										className="form-control border-0"
										value={startDate}
										onChange={(event) => setStartDate(event.target.value)}
									/>
									<input
										type="date"
										name="endDate"
										className="form-control  border-0"
										value={endDate}
										onChange={(event) => setEndDate(event.target.value)}
									/>
									<div style={{ marginLeft: '10px' }}>
										<button className="btn btn-primary" type="submit">
											<i className="fas fa-search fa-sm" />
										</button>
									</div>
									{(country || committe || office || startDate || endDate) && (
										<div style={{ marginLeft: '10px' }}>
											<button className="btn btn-primary" onClick={handleClickClear}>
												Clean Fields
											</button>
										</div>
									)}
									{clear && (
										<div style={{ marginLeft: '10px' }}>
											<button className="btn btn-primary" onClick={handleClickRefresh}>
												Start
											</button>
										</div>
									)}
									{error && (
										<p style={{ margin: '5px' }} className="text-danger">
											{error}
										</p>
									)}
								</div>
							</li>
						</ul>
					</form>
				</Col>
			</Row>
			<Row>
				<Col md="4">
					<Card title="Total" value={total} max={total} />
				</Col>
				<Col md="4">
					<Card title="In progress" value={open} max={total} />
				</Col>
				<Col md="4">
					<Card title="Concluded" value={close} max={total} />
				</Col>
			</Row>
			<br />
			{''}
			<Row>
				<Col xl="6" lg="6">
					<Panel title="Category">
						<Pie data={category} width={100} height={35} options={circleOptions} />
					</Panel>
				</Col>
				<Col xl="6" lg="6">
					<Panel title="Sub Category">
						<Pie data={subcategory} width={100} height={35} options={circleOptions} />
					</Panel>
				</Col>
			</Row>
			<Row>
				<Col xl="4" lg="4">
					<Panel title="Area">
						<Bar data={area} width={100} height={70} options={lineOptions} />
					</Panel>
				</Col>
				<Col xl="4" lg="4">
					<Panel title="Status">
						<Bar data={status} width={100} height={70} options={lineOptions} />
					</Panel>
				</Col>
				<Col xl="4" lg="4">
					<Panel title="Offence Since">
						<Bar data={since} width={100} height={70} options={barOptionsPercentage} />
					</Panel>
				</Col>
			</Row>
			<Row>
				<Col xl="6" lg="6">
					<Panel title="Conclusion">
						<Doughnut data={conclution} width={100} height={50} options={donutOptions} />
					</Panel>
				</Col>
				<Col xl="6" lg="6">
					<Panel title="Offence Level">
						<Bar data={offence} width={100} height={50} options={barOptionsPercentage} />
					</Panel>
				</Col>
			</Row>
			<Row>
				<Col xl="6" lg="6">
					<Panel title="Remediation Plan Classification">
						<Bar data={plan} width={100} height={50} options={lineOptions} />
					</Panel>
				</Col>
				<Col xl="6" lg="6">
					<Panel title="Remediation Plan Subclassification">
						<Pie data={subPlan} width={100} height={50} options={circleOptions} />
					</Panel>
				</Col>
			</Row>
			<Row>
				<Col xl="12" lg="12">
					<Panel title="Activity Chart">
						<Bar data={activity} width={100} height={20} options={lineOptions} />
					</Panel>
				</Col>
			</Row>
			<Row>
				<Col xl="12" lg="12">
					<Panel title="Activity Detail">
						<DataTable data={activityDetail} structure={columns} tableName="Activity Detail" />
					</Panel>
				</Col>
			</Row>
		</DashboardLayout>
	)
}

export default Dashboard
