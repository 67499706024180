import React, { useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { actions } from './modules/Auth/auth.redux'
import Alert from './modules/Alert/Alert'
import Router from './router'

function App({ checkLogin }) {
  useEffect(() => {
    checkLogin()
  }, [checkLogin])

  return (
    <Fragment>
      <Alert />
      <Router />
    </Fragment>
  )
}
const mapDispatchToProps = {
  checkLogin: actions.checkLoginRequest
}

export default connect(null, mapDispatchToProps)(App)
