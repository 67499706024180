/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/alt-text */
import React, { Fragment } from 'react'
import { connect, useSelector } from 'react-redux'
import { actions } from '../../modules/Auth/auth.redux'
import { Link } from 'react-router-dom'
import logo from 'Logo-SofttekWhite.svg'

export const DashboardLayout = ({ auth = {}, children, logout }) => {
	const user = useSelector((state) => state.auth)
	const role = user.role
	const currentYear = new Date().getFullYear();

	return (
		<Fragment>
			{/* Page Wrapper */}
			<div id="wrapper">
				{/* Sidebar */}
				<ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
					{/* Sidebar - Brand */}
					<a className="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
						<div className="sidebar-brand-icon rotate-n-15">
							{/* <i className='fas fa-laugh-wink' /> */}
						</div>
						<div className="sidebar-brand-text mx-3">
							Integrity <sup>2</sup>
						</div>
					</a>
					{/* Divider */}
					<hr className="sidebar-divider my-0" />
					{/* Nav Item - Dashboard */}
					<li className="nav-item">
						<Link className="nav-link" to="/dashboard" activeClassName="active">
							<i class="fas fa-chart-line" style={{ fontSize: '.90rem', marginRight: '.90rem' }} />
							<span>Dashboard</span>
						</Link>
					</li>
					<li className="nav-item">
						<Link className="nav-link" to="/sla" activeClassName="active">
							<i class="fas fa-calendar-check" style={{ fontSize: '.90rem', marginRight: '.90rem' }} />
							<span>SLA</span>
						</Link>
					</li>
					{role === 6 && (
						<li className="nav-item">
							<Link className="nav-link" to="/visits" activeClassName="active">
								<i class="fas fa-eye" style={{ fontSize: '.90rem', marginRight: '.90rem' }} />
								<span>Visits</span>
							</Link>
						</li>
					)}

					{/* Divider */}
					<hr className="sidebar-divider" />
					<div className="sidebar-logo">
						<img src={logo} alt="Logo" />
					</div>
				</ul>
				{/* End of Sidebar */}
				{/* Content Wrapper */}
				<div id="content-wrapper" className="d-flex flex-column">
					{/* Main Content */}
					<div id="content">
						{/* Topbar */}
						<nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
							{/* Sidebar Toggle (Topbar) */}
							<button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
								<i className="fa fa-bars" />
							</button>

							{/* Topbar Navbar */}
							<ul className="navbar-nav ml-auto">
								{/* Nav Item - Search Dropdown (Visible Only XS) */}
								<li className="nav-item dropdown no-arrow d-sm-none">
									<a
										className="nav-link dropdown-toggle"
										href="#"
										id="searchDropdown"
										role="button"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<i className="fas fa-search fa-fw" />
									</a>
									{/* Dropdown - Messages */}
									<div
										className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
										aria-labelledby="searchDropdown"
									>
										<form className="form-inline mr-auto w-100 navbar-search">
											<div className="input-group">
												<input
													type="text"
													className="form-control bg-light border-0 small"
													placeholder="Search for..."
													aria-label="Search"
													aria-describedby="basic-addon2"
												/>
												<div className="input-group-append">
													<button className="btn btn-primary" type="button">
														<i className="fas fa-search fa-sm" />
													</button>
												</div>
											</div>
										</form>
									</div>
								</li>
								<div className="topbar-divider d-none d-sm-block" />
								{/* Nav Item - User Information */}
								<li className="nav-item dropdown no-arrow">
									<a
										className="nav-link dropdown-toggle"
										href="#"
										id="userDropdown"
										role="button"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<span className="mr-2 d-none d-lg-inline text-gray-600 small">
											{auth.name} {auth.lastName}
										</span>
										{/* <img
											className="img-profile rounded-circle"
											src="https://source.unsplash.com/QAB-WJcbgJk/60x60"
										/> */}
									</a>
									{/* Dropdown - User Information */}
									<div
										className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
										aria-labelledby="userDropdown"
									>
										<a className="dropdown-item" href="#" onClick={logout}>
											<i class="fas fa-sign-out-alt mr-2 text-gray-400" />
											Logout
										</a>
									</div>
								</li>
							</ul>
						</nav>
						{/* End of Topbar */}
						{/* Begin Page Content */}
						<div className="container-fluid">{children}</div>
						{/* /.container-fluid */}
					</div>
					{/* End of Main Content */}
					{/* Footer */}
					<footer className="sticky-footer bg-white">
						<div className="container my-auto">
							<div className="copyright text-center my-auto">
							<span>{"© Valores Corporativos Softtek S.A. de C.V. " + currentYear} </span>
							</div>
						</div>
					</footer>
					{/* End of Footer */}
				</div>
				{/* End of Content Wrapper */}
			</div>

			{/* Scroll to Top Button*/}
			<a className="scroll-to-top rounded" href="#page-top">
				<i className="fas fa-angle-up" />
			</a>
		</Fragment>
	)
}

const mapStateToProps = ({ auth }) => ({ auth })
const mapDispatchToProps = { logout: actions.logoutRequest }

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout)
