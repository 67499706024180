import { createActions, createReducer } from 'reduxsauce'

const { Types, Creators } = createActions(
  {
    loadAllUserRequest: ['payload'],
    loadAllUserSuccess: ['payload'],
    loadAllUserFailure: ['payload'],

    loadUserRequest: ['payload'],
    loadUserSuccess: ['payload'],
    loadUserFailure: ['payload'],

    createUserRequest: ['payload'],
    createUserSuccess: ['payload'],
    createUserFailure: ['payload'],

    editUserRequest: ['payload'],
    editUserSuccess: ['payload'],
    editUserFailure: ['payload'],

    updateUserRequest: ['payload'],
    updateUserSuccess: ['payload'],
    updateUserFailure: ['payload'],

    toggleUserRequest: ['payload'],
    toggleUserSuccess: ['payload'],
    toggleUserFailure: ['payload']
  },
  {}
)

const initialState = {
  list: [],
  view: {},
  edit: {}
}

export const HANDLERS = {
  [Types.LOAD_ALL_USER_SUCCESS]: function (state, action) {
    return { ...state, list: [...action.payload] }
  },
  [Types.LOAD_USER_SUCCESS]: function (state, action) {
    return { ...state, view: { ...action.payload } }
  },
  [Types.EDIT_USER_SUCCESS]: function (state, action) {
    return { ...state, edit: { ...action.payload } }
  }
}

export const userTypes = Types
export const userActions = Creators
export const user = createReducer(initialState, HANDLERS)
