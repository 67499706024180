import React from 'react'
import styled from '@emotion/styled'
import ExportExcel from './../../components/Table/ExportExcel'

const Text = styled.div`
	flex: 1 1 auto;
	min-height: 1px;
	padding: .5rem 1rem;
	text-align: center;
	font-weight: bold;
	font-size: 1.5rem;
`
const Subtitle = styled.div`
	flex: 1 1 auto;
	min-height: 1px;
	padding: 0 0 .5rem 0;
	text-align: center;
	font-size: 1rem;
`
const Committee = styled.h2`
	font-size: 1.4rem;
	text-transform: uppercase;
`

export function Heading({ committee }) {
	return <Committee>Comité: {committee}</Committee>
}

Heading.defaultProps = {
	Committee: 'Committee'
}

export function Info({ title, value, subtitle }) {
	return (
		<div className="card shadow mb-4">
			<div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
				<h6 className="m-auto font-weight-bold text-primary">{title}</h6>
			</div>
			<Text>{value}</Text>
			<Subtitle>{subtitle}</Subtitle>
		</div>
	)
}

Info.defaultProps = {
	title: 'Title',
	value: 'Value',
	subtitle: 'Subtitle'
}

export function Panel({ title, ...props }) {
	return (
		<div className="card shadow mb-4">
			<div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
				<h6 className="m-0 font-weight-bold text-primary">{title}</h6>
				{props.enableExport && <ExportExcel columnsToExport={props.columnsToExport} data={props.data} />}
			</div>
			<div className="card-body">{props.children}</div>
		</div>
	)
}

Panel.defaultProps = {
	title: 'Title'
}

export function Card({ title, value, max }) {
	const por = value * 100 / max
	return (
		<div className="card border-left-info shadow h-100 py-2">
			<div className="card-body">
				<div className="row no-gutters align-items-center">
					<div className="col mr-2">
						<div className="text-xs font-weight-bold text-info text-uppercase mb-1">
							{title} {por.toFixed(2)}%
						</div>
						<div className="row no-gutters align-items-center">
							<div className="col-auto">
								<div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">{value}</div>
							</div>
							<div className="col">
								<div className="progress progress-sm mr-2">
									<div
										className="progress-bar bg-info"
										role="progressbar"
										style={{ width: `${por}%` }}
										aria-valuenow={por}
										aria-valuemin={0}
										aria-valuemax={100}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="col-auto">
						<i className="fas fa-clipboard-list fa-2x text-gray-300" />
					</div>
				</div>
			</div>
		</div>
	)
}

Card.defaultProps = {
	title: 'Title',
	total: 0,
	max: 0
}
