import { combineReducers } from 'redux'
// app reducer's
import { reducer as form } from 'redux-form'
import { createBrowserHistory } from 'history'
import { connectRouter } from 'connected-react-router'
/* PLOP_INJECT_IMPORT */
import { app } from './app.reducer'
import { show } from './../global'
import { auth } from './../../modules/Auth/auth.redux'
import { user } from './../../modules/User/user.redux'
import { alert } from './../../modules/Alert/alert.redux'

export const router = connectRouter(createBrowserHistory())

export default combineReducers({
  /* PLOP_INJECT_EXPORT */
  router,
  form,
  app,
  show,
  auth,
  user,
  alert
})
