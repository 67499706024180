import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import LoginForm from './LoginForm'
import { actions } from '../../modules/Auth/auth.redux'

export const Login = ({ auth, loginRequest, ...props }) => {
	useEffect(() => {
		const body = document.body
		body.classList.add('bg-gradient-primary')
		return () => {
			body.classList.remove('bg-gradient-primary')
		}
		// eslint-disable-next-line
	}, [])

	useEffect(
		() => {
			if (auth.isAuthenticated) props.history.push('/dashboard')
		},
		[ auth.isAuthenticated ]
	)

	const [ form, setForm ] = useState({
		email: '',
		password: '',
		remember: false
	})

	function handleOnSubmit(event) {
		event.preventDefault()
		loginRequest(form)
	}

	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-xl-6 col-lg-6 col-md-6">
					<div className="card o-hidden border-0 shadow-lg my-5">
						<div className="card-body p-0">
							<div className="p-5">
								<div className="text-center">
									<h1 className="h4 text-gray-900 mb-4">Iniciar sesión</h1>
								</div>
								<LoginForm onChange={setForm} values={form} onSubmit={handleOnSubmit} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({ auth: state.auth })
const mapDispatchToProps = { loginRequest: actions.loginRequest }

export default connect(mapStateToProps, mapDispatchToProps)(Login)
