/* PLOP_INJECT_IMPORT */
import Login from './../components/Login/Login'
import Dashboard from './../components/Dashboard/Dashboard'
import Sla from './../components/Sla/Sla'
import Visits from './../components/Visits/Visits'
// import userRoutes from './../modules/User/user.routes'

export const routes = [
	/* PLOP_INJECT_EXPORT */
	{ path: '/', component: Login },
	{ path: '/dashboard', component: Dashboard, protected: true },
	{ path: '/sla', component: Sla, protected: true },
	{ path: '/visits', component: Visits, protected: true }
	// ...userRoutes
]
