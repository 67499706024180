import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Row, Col } from 'reactstrap'
import { Pie } from 'react-chartjs-2'
import 'chartjs-plugin-datalabels'

import { Panel, Info, Heading } from './../../components/Dashboard/Panel'
import DashboardLayout from './../Dashboard/DashboardLayout'
import DataTable from './../Table/Table'
import {
	columnsSla,
	columnsTime,
	formatterSlaInvestigation,
	formatterSlaOpen,
	formatterSlaReady,
	daysOpen,
	daysInvestigation,
	daysReady
} from './columns'

import axios from 'axios'
import { chartData } from './../../helpers'

export const Sla = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	  }, []);
	const user = useSelector((state) => state.auth)
	const role = user.role

	const circleOptions = {
		legend: { display: true, position: 'right' },
		plugins: {
			datalabels: {
				formatter: (value, ctx) => {
					let sum = 0
					let dataArr = ctx.chart.data.datasets[0].data
					dataArr.map((data) => {
						sum += data
					})
					let percentage = (value * 100 / sum).toFixed() + '%'
					return percentage
				},
				color: '#fff'
			}
		},
		tooltips: {
			enabled: false
		}
	}

	const [ offices, setOffices ] = useState([])
	const [ filteredOffices, setFilteredOffices ] = useState([])
	const [ filteredCommittes, setFilteredCommittes ] = useState([])

	async function requestOffices() {
		const result = await axios.post('/api/inputs/offices')
		const data = result.data
		const officeList = user.role >= 5 ? data : data.filter((item) => item.committee_id === user.committee_id)
		setOffices(officeList)
		setFilteredOffices(officeList)
	}

	const [ committes, setCommittes ] = useState([])
	async function requestCommittes() {
		const result = await axios.post('/api/inputs/committes')
		const data = result.data
		setCommittes(data)
		setFilteredCommittes(data)
	}

	const [ countries, setCountries ] = useState([])
	async function requestCountries() {
		const result = await axios.post('/api/inputs/countries')
		const data = result.data
		setCountries(data)
	}

	const [ open, setOpen ] = useState({})
	const [ inv, setInv ] = useState({})
	const [ ready, setReady ] = useState({})
	const [ percentageOpenOnTime, setPercentageOpenOnTime ] = useState(0)
	const [ percentageInvOnTime, setPercentageInvOnTime ] = useState(0)
	const [ percentageReadyOnTime, setPercentageReadyOnTime ] = useState(0)
	async function requestSla() {
		const result = await axios.post('/api/reportsSla/sla', { startDate, endDate, office, country, committe })

		const openOnTime = result.data.filter((item) => {
			return formatterSlaOpen(null, item) === 'On Time'
		}).length
		const openOutOfTime = result.data.filter((item) => {
			return formatterSlaOpen(null, item) === 'Out Of Time'
		}).length

		const openChart = chartData('SLA Open', [ 'On time', 'Out Of Time' ], [ openOnTime, openOutOfTime ])
		setOpen(openChart)
		const perentageOnTime = (openOnTime * 100 / (openOnTime + openOutOfTime)).toFixed() + '%'

		setPercentageOpenOnTime(perentageOnTime)

		const invOnTime = result.data.filter((item) => {
			return formatterSlaInvestigation(null, item) === 'On Time'
		}).length
		const invOutOfTime = result.data.filter((item) => {
			return formatterSlaInvestigation(null, item) === 'Out Of Time'
		}).length

		const invChart = chartData('SLA Open', [ 'On time', 'Out Of Time' ], [ invOnTime, invOutOfTime ])
		setInv(invChart)
		const perentageInvOnTime = (invOnTime * 100 / (invOnTime + invOutOfTime)).toFixed() + '%'

		setPercentageInvOnTime(perentageInvOnTime)

		const readyOnTime = result.data.filter((item) => {
			return formatterSlaReady(null, item) === 'On Time'
		}).length
		const readyOutOfTime = result.data.filter((item) => {
			return formatterSlaReady(null, item) === 'Out Of Time'
		}).length

		const readyChart = chartData('SLA Open', [ 'On time', 'Out Of Time' ], [ readyOnTime, readyOutOfTime ])
		setReady(readyChart)
		const perentageReadyOnTime = (readyOnTime * 100 / (readyOnTime + readyOutOfTime)).toFixed() + '%'

		setPercentageReadyOnTime(perentageReadyOnTime)
	}

	const [ slaDetail, setSlaDetail ] = useState([])
	const [ averageOpenDays, setAverageOpenDays ] = useState(0)
	const [ averageInvDays, setAverageInvDays ] = useState(0)
	const [ averageReadyDays, setAverageReadyDays ] = useState(0)
	async function requestSlaDetail() {
		const result = await axios.post('/api/reportsSla/sla/detail', {
			startDate,
			endDate,
			office,
			country,
			committe
		})
		const data = result.data
		setSlaDetail(data)

		const filteredOpen = data.filter((item) => daysOpen(item) !== null)
		const avgOpen = filteredOpen.reduce((pre, cur) => pre + daysOpen(cur) / filteredOpen.length, 0)
		const avgOpenFix = avgOpen.toFixed()

		setAverageOpenDays(avgOpenFix)

		const filteredInv = data.filter((item) => daysInvestigation(item) !== null)
		const avgInv = filteredInv.reduce((pre, cur) => pre + daysInvestigation(cur) / filteredInv.length, 0)
		const avgInvFix = avgInv.toFixed()

		setAverageInvDays(avgInvFix)

		const filteredReady = data.filter((item) => daysReady(item) !== null)
		const avgReady = filteredReady.reduce((pre, cur) => pre + daysReady(cur) / filteredReady.length, 0)
		const avgReadyFix = avgReady.toFixed()

		setAverageReadyDays(avgReadyFix)
	}

	useEffect(() => {
		async function init() {
			await requestSla()
			await requestSlaDetail()
			await requestOffices()
			await requestCommittes()
			await requestCountries()
		}
		init()
	}, [])

	const [ startDate, setStartDate ] = useState('')
	const [ endDate, setEndDate ] = useState('')
	const [ country, setCountry ] = useState('')
	const [ office, setOffice ] = useState('')
	const [ committe, setCommitte ] = useState('')
	const [ error, setError ] = useState('')

	async function handleOnSubmit(event) {
		event.preventDefault()

		if (Date.parse(startDate) > Date.parse(endDate)) {
			//start is less than End
			setError('La fecha de inicio debe ser menor a la de fin.')
			return
		}

		setError('')

		await requestSla()
		await requestSlaDetail()
	}

	const [ clear, setClear ] = useState(false)
	function handleClickClear() {
		setStartDate('')
		setEndDate('')
		setCountry('')
		setOffice('')
		setCommitte('')
		setClear(true)
	}

	async function handleClickRefresh() {
		await requestOffices()
		await requestCommittes()
		await requestCountries()
		await requestSla()
		await requestSlaDetail()
		await requestOffices()
		await requestCommittes()
		await requestCountries()

		setClear(false)
	}

	function handleChangeCountry(event) {
		const value = event.target.value
		const officesByCountry = offices.filter((item) => item.country === value)
		const committesId = [ ...new Set(officesByCountry.map((item) => item.committee_id)) ]
		const committesByCountry = committes.filter((item) => committesId.includes(item.id))
		setCountry(value)
		setFilteredOffices(officesByCountry)
		setFilteredCommittes(committesByCountry)
	}

	function handleChangeCommitte(event) {
		const value = event.target.value
		const officesByCommitte = offices.filter((item) => item.committee_id === parseInt(value))
		setCommitte(value)
		setFilteredOffices(officesByCommitte)
	}

	function handleChangeOffice(event) {
		const value = event.target.value
		setOffice(value)
	}

	return (
		<DashboardLayout>
			{role >= 5 ? (
				<Row>
					<Col lg="4">
						<form onSubmit={handleOnSubmit}>
							<div className="input-group">
								<select
									name="country"
									className="form-control border-0"
									value={country}
									onChange={handleChangeCountry}
								>
									<option value="" selected="selected">
										Selecciona el país
									</option>
									{countries.map((item) => (
										<option key={`countrySla_${item.id}`} value={item.countryName}>
											{item.countryName}
										</option>
									))}
								</select>
							</div>
						</form>
					</Col>
					<Col lg="4">
						<form onSubmit={handleOnSubmit}>
							<div className="input-group">
								<select
									name="committe"
									className="form-control  border-0 "
									value={committe}
									onChange={handleChangeCommitte}
								>
									<option value="" selected="selected">
										Selecciona el comité
									</option>
									{filteredCommittes.map((item) => (
										<option key={`committeSla_${item.id}`} value={item.id}>
											{item.commiteName}
										</option>
									))}
								</select>
							</div>
						</form>
					</Col>
					<Col lg="4">
						<form onSubmit={handleOnSubmit}>
							<div className="input-group">
								<select
									name="office"
									className="form-control  border-0 "
									value={office}
									onChange={(event) => setOffice(event.target.value)}
								>
									<option value="" selected="selected">
										Selecciona la oficina
									</option>
									{filteredOffices.map((item) => (
										<option key={`officeSla_${item.id}`} value={item.id}>
											{item.officeName}
										</option>
									))}
								</select>
							</div>
						</form>
					</Col>
				</Row>
			) : (
				<Row>
					<Col lg="6">
						<Heading committee={user.committee_title} />
					</Col>
					<Col lg="4">
						<form onSubmit={handleOnSubmit}>
							<div className="input-group">
								<select
									name="office"
									className="form-control  border-0 "
									value={office}
									onChange={handleChangeOffice}
								>
									<option value="" selected="selected">
										Selecciona la oficina
									</option>
									{filteredOffices.map((item) => (
										<option key={`officeSlaRole_${item.id}`} value={item.id}>
											{item.officeName}
										</option>
									))}
								</select>
							</div>
						</form>
					</Col>
				</Row>
			)}
			<br />
			{''}
			<Row>
				<Col className="d-flex flex-row align-items-center">
					<form onSubmit={handleOnSubmit}>
						<ul className="list-inline">
							<li className="list-inline-item">
								<div className="input-group">
									<input
										type="date"
										name="startDate"
										className="form-control border-0"
										value={startDate}
										onChange={(event) => setStartDate(event.target.value)}
									/>
									<input
										type="date"
										name="endDate"
										className="form-control  border-0"
										value={endDate}
										onChange={(event) => setEndDate(event.target.value)}
									/>
									<div style={{ marginLeft: '10px' }}>
										<button className="btn btn-primary" type="submit">
											<i className="fas fa-search fa-sm" />
										</button>
									</div>
									{(country || committe || office || startDate || endDate) && (
										<div style={{ marginLeft: '10px' }}>
											<button className="btn btn-primary" onClick={handleClickClear}>
												Clean Fields
											</button>
										</div>
									)}
									{clear && (
										<div style={{ marginLeft: '10px' }}>
											<button className="btn btn-primary" onClick={handleClickRefresh}>
												Start
											</button>
										</div>
									)}
									{error && (
										<p style={{ margin: '5px' }} className="text-danger">
											{error}
										</p>
									)}
								</div>
							</li>
						</ul>
					</form>
				</Col>
			</Row>
			<Row>
				<Col md="4">
					<Info title="SLA - Open to Investigation" value="3" subtitle="Business Days" />
				</Col>
				<Col md="4">
					<Info title="SLA - Inv to Ready for Opinion" value="20" subtitle="Business Days" />
				</Col>
				<Col md="4">
					<Info title="SLA - Ready for Opinion to Ended" value="5" subtitle="Business Days" />
				</Col>
			</Row>
			<Row>
				<Col xl="4" lg="4">
					<Panel title="SLA Open">
						<Pie data={open} width={100} height={35} options={circleOptions} />
					</Panel>
				</Col>
				<Col xl="4" lg="4">
					<Panel title="SLA Investigation">
						<Pie data={inv} width={100} height={35} options={circleOptions} />
					</Panel>
				</Col>
				<Col xl="4" lg="4">
					<Panel title="SLA Ready for Opinion">
						<Pie data={ready} width={100} height={35} options={circleOptions} />
					</Panel>
				</Col>
			</Row>
			<Row>
				<Col md="2">
					<Info title="Average" value={averageOpenDays} subtitle="Open Days" />
				</Col>
				<Col md="2">
					<Info title="On Time" value={percentageOpenOnTime} subtitle="Porcentaje" />
				</Col>
				<Col md="2">
					<Info title="Average" value={averageInvDays} subtitle="Open Days" />
				</Col>
				<Col md="2">
					<Info title="On Time" value={percentageInvOnTime} subtitle="Porcentaje" />
				</Col>
				<Col md="2">
					<Info title="Average" value={averageReadyDays} subtitle="Open Days" />
				</Col>
				<Col md="2">
					<Info title="On Time" value={percentageReadyOnTime} subtitle="Porcentaje" />
				</Col>
			</Row>
			<Row>
				<Col xl="12" lg="12">
					<Panel title="SLA Status">
						<DataTable data={slaDetail} structure={columnsTime} tableName="SLA Status" />
					</Panel>
				</Col>
			</Row>
			<Row>
				<Col xl="12" lg="12">
					<Panel title="SLA Dates">
						<DataTable data={slaDetail} structure={columnsSla} tableName="SLA Dates" />
					</Panel>
				</Col>
			</Row>
		</DashboardLayout>
	)
}

export default Sla
