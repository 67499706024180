import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import createSagaMiddleware from 'redux-saga'

import monitorReducersEnhancer from './enhancers/monitorReducer'
import rootReducer from './reducers'
import errorMiddleware from './middlewares/error.middleware'
import loggerMiddleware from './middlewares/logger'
import { initSagas } from './init.sagas'

export const history = createBrowserHistory()
export default function configureStore(preloadedState) {
  let store = null
  const sagaMiddleware = createSagaMiddleware()

  const middlewares = [
    errorMiddleware,
    loggerMiddleware,
    routerMiddleware(history),
    sagaMiddleware
  ]

  const middlewareEnhancer = applyMiddleware(...middlewares)
  const enhancers = [middlewareEnhancer, monitorReducersEnhancer]

  store = createStore(rootReducer, preloadedState, composeWithDevTools(...enhancers))

  initSagas(sagaMiddleware)
  return store
}
