import axios from 'axios'
import { push } from 'connected-react-router'
import { all, takeLatest, call, put, take } from 'redux-saga/effects'
import { alertActions, alertTypes } from './../../modules/Alert/alert.redux'
import { userActions as actions, userTypes as types } from './user.redux'
import { isEmpty } from './../../helpers/util.helpers'

/**
 * Display the users list
 * @returns {iterator}
 */
export function* loadAllUser() {
  try {
    const url = '/api/users/'
    const { data: users } = yield call(axios.get, url)
    yield put(actions.loadAllUserSuccess(users))
  } catch (error) {
    yield put(actions.loadAllUserFailure(error))
    console.log(error)
  }
}

/**
 * Display a single user record
 * @param   {object}   action.payload Data to take the id of the requested user
 * @returns {iterator}
 */
export function* loadUser({ payload } = {}) {
  try {
    const { id } = payload
    const url = `/api/users/${id}`
    const { data: user } = yield call(axios.get, url)
    yield put(actions.loadUserSuccess(user))
  } catch (error) {
    yield put(actions.loadUserFailure(error))
    console.log(error)
  }
}

/**
 * Create an user record
 * @param   {object}   action.payload Data to create an user record
 * @returns {iterator}
 */
export function* createUser({ payload }) {
  try {
    const url = '/api/users/'
    const { data } = yield call(axios.post, url, payload)
    yield put(actions.createUserSuccess(data))
    yield put(alertActions.alertMessageSuccess('Registro guardado'))
    yield put(push('/dashboard/user/list'))
  } catch (error) {
    yield put(actions.createUserFailure(error))
    console.log(error)
  }
}

/**
 * Load the information of a single user record to edit it
 * @param   {object}   action.payload Data to take the id of the requested user
 * @returns {iterator}
 */
export function* editUser({ payload } = {}) {
  try {
    const { id } = payload
    const url = `/api/users/${id}`
    const { data } = yield call(axios.get, url)
    yield put(actions.editUserSuccess(data))
  } catch (error) {
    yield put(actions.editUserFailure(error))
    console.log(error)
  }
}

/**
 * Update an user record
 * @param   {object}   action.payload Data to update an user record
 * @returns {iterator}
 */
export function* updateUser({ payload }) {
  try {
    yield put(alertActions.alertPromptShow())
    const prompt = yield take(alertTypes.ALERT_PROMPT_HIDE)

    if (isEmpty(prompt.payload)) return

    const { id, values } = payload
    const url = `/api/users/${id}`

    const historical = { cause: 'Actualización', description: prompt.payload }
    const data = { payload: values, historical }
    const { data: updatedUser } = yield call(axios.put, url, data)
    yield put(actions.updateUserSuccess(updatedUser))
    yield put(alertActions.alertMessageSuccess('Registro actualizado'))
    yield put(push('/dashboard/user/list'))
  } catch (error) {
    yield put(actions.updateUserFailure(error))
    console.log(error)
  }
}

/**
 * Toggle the active property of an user record
 * @param   {object}   action.payload Data to update an user record
 * @returns {iterator}
 */
export function* toggleUser({ payload }) {
  try {
    yield put(alertActions.alertPromptShow())
    const prompt = yield take(alertTypes.ALERT_PROMPT_HIDE)
    const description = prompt.payload

    // The prompt was closed, stop the flow
    if (isEmpty(description)) return
    const { _id, active } = payload
    const url = `/api/users/${_id}`

    const historical = { cause: active ? 'Desactivación' : 'Activación', description }
    const { data: updatedUser } = yield call(axios.put, url, {
      payload: { active: !active },
      historical
    })

    yield put(actions.toggleUserSuccess(updatedUser))

    // Show success notification
    const result = updatedUser.active ? 'activado' : 'desactivado'
    yield put(alertActions.alertMessageSuccess(`Registro ${result}`))
    yield put(push('/dashboard/user/list'))
  } catch (error) {
    yield put(actions.toggleUserFailure(error))
    console.log(error)
  }
}

/**
 * Validate an user record
 * @param     values
 * @returns
 */
export async function validateUser(values) {
  try {
    const url = '/api/users/validate'
    const { data: result } = await axios.post(url, values)
    if (!isEmpty(result.errors)) throw result.errors
  } catch (error) {
    throw error
  }
}

export function* userSagas() {
  yield all([
    takeLatest(types.LOAD_ALL_USER_REQUEST, loadAllUser),
    takeLatest(types.LOAD_USER_REQUEST, loadUser),
    takeLatest(types.CREATE_USER_REQUEST, createUser),
    takeLatest(types.EDIT_USER_REQUEST, editUser),
    takeLatest(types.UPDATE_USER_REQUEST, updateUser),
    takeLatest(types.TOGGLE_USER_REQUEST, toggleUser)
  ])
}
